import {
  configApiRef,
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  microsoftAuthApiRef
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { ProductMetaApiClient, productMetaApiRef } from './apis/product.api';

export const productMetaPlugin = createPlugin({
  id: 'product-meta',
  routes: {
    root: rootRouteRef
  },
  apis: [
    // Add ProductMetaApi
    createApiFactory({
      api: productMetaApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        microsoftAuthApi: microsoftAuthApiRef,
        configApi: configApiRef
      },
      factory: ({ discoveryApi, microsoftAuthApi, configApi }) =>
        new ProductMetaApiClient(discoveryApi, microsoftAuthApi, configApi)
    })
  ]
});

export const ProductMetaPage = productMetaPlugin.provide(
  createRoutableExtension({
    name: 'ProductMetaRoot',
    component: async () => (await import('./components/RootComponent')).RootComponent,
    mountPoint: rootRouteRef
  })
);
