import {
  BackstageIdentityApi,
  ConfigApi,
  DiscoveryApi,
  OAuthApi,
  OpenIdConnectApi,
  ProfileInfoApi,
  SessionApi
} from '@backstage/core-plugin-api';
import { ApiError } from './apiError';

export type MicrosoftAuthApi = OAuthApi & OpenIdConnectApi & ProfileInfoApi & BackstageIdentityApi & SessionApi;

export class BaseProductMetaApiClient {
  private apiScopes: string[] | string = [];

  readonly discoveryApi: DiscoveryApi;
  readonly microsoftAuthApi: MicrosoftAuthApi;

  constructor(discoveryApi: DiscoveryApi, microsoftAuthApi: MicrosoftAuthApi, configApi: ConfigApi) {
    this.discoveryApi = discoveryApi;
    this.microsoftAuthApi = microsoftAuthApi;

    const msConfig = configApi.getOptionalConfig('auth.providers.microsoft');
    const msConfigEnv = configApi.getOptionalString('auth.environment');
    this.apiScopes = msConfig?.getStringArray(`${msConfigEnv}.additionalScopes`) ?? [];
  }

  async fetch(input: string, init?: RequestInit): Promise<Response> {
    // As configured previously for the backend proxy
    const proxyUri = `${await this.discoveryApi.getBaseUrl('proxy')}/product-meta`;

    const resp = await fetch(`${proxyUri}${input}`, init);
    if (!resp.ok) {
      const body = await resp.json();
      throw new ApiError(body);
    }

    return resp;
  }

  async fetchWithAuth(input: string, init?: RequestInit): Promise<Response> {
    const token = await this.microsoftAuthApi.getAccessToken(this.apiScopes);
    if (!token) {
      throw new Error('Could not get Bearer Token');
    }

    return await this.fetch(input, {
      ...init,
      headers: {
        ...init?.headers,
        Authorization: `Bearer ${token}`
      }
    });
  }
}
