import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { apis } from './apis';
import { Root } from './components/Root';
import { AlertDisplay, OAuthRequestDialog } from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import {
  catalogPlugin,
  CatalogIndexPage,
  CatalogEntityPage,
} from '@backstage/plugin-catalog';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { ProductMetaPage } from '@internal/plugin-product-meta';
import CustomSignInPage from './components/SignIn/SignInPage';
import { microsoftAuthApiRef } from '@backstage/core-plugin-api';
import DarkIcon from '@mui/icons-material/Brightness2';
import LightIcon from '@mui/icons-material/WbSunny';
import { UnifiedThemeProvider } from '@backstage/theme';
import {
  customUnifiedDarkTheme,
  customUnifiedLightTheme,
} from './components/theme/cariad-theme';
import { ScaffolderFieldExtensions } from '@backstage/plugin-scaffolder-react';
import { JumphostDetailsExtension } from '@internal/backstage-plugin-scaffolder-jumphost-react';

const app = createApp({
  apis,
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
  },
  components: {
    SignInPage: props => (
      <CustomSignInPage
        {...props}
        auto
        provider={{
          id: 'microsoft-auth-provider',
          title: 'Azure',
          message: 'Sign in using Azure',
          apiRef: microsoftAuthApiRef,
        }}
      />
    ),
  },
  themes: [
    {
      id: 'custom-light',
      title: 'Light Theme',
      variant: 'light',
      icon: <LightIcon />,
      Provider: ({ children }) => (
        <UnifiedThemeProvider
          theme={customUnifiedLightTheme}
          children={children}
        />
      ),
    },
    {
      id: 'custom-dark',
      title: 'Dark Theme',
      variant: 'dark',
      icon: <DarkIcon />,
      Provider: ({ children }) => (
        <UnifiedThemeProvider
          theme={customUnifiedDarkTheme}
          children={children}
        />
      ),
    },
  ],
});

const routes = (
  <FlatRoutes>
    <Route path="/" element={<Navigate to="product-meta" />} />
    <Route path="/product-meta" element={<ProductMetaPage />} />
    <Route path="/create" element={<ScaffolderPage />}>
      <ScaffolderFieldExtensions>
        <JumphostDetailsExtension />
      </ScaffolderFieldExtensions>
    </Route>
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
    <Route path="/catalog" element={<CatalogIndexPage />} />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    />
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
