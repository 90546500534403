import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 60,
  },
});
const LogoFull = () => {
  const classes = useStyles();

  return (
    <svg
      className={classes.svg}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 800 300"
    >
      <defs>
        <style>
          {`
						.cls-1 {
							fill: #fff;
						}
						
						.cls-2 {
							font-size: 149px;
							font-family: FKCARIAD-Light, FK CARIAD;
							font-weight: 300;
							fill: white;
						}
						
						.cls-5 {
							fill: #1d0638;
						}
						
						.cls-3, .cls-4 {
							font-family: CARIAD.CODE-Regular, "CARIAD.CODE";
							font-weight: 400;
						}
						
						.cls-4 {
							letter - spacing: 0em;
						}
						
						.cls-6 {
							fill: #1d4050;
							stroke-width: 0.25px;
						}
						
						.cls-6, .cls-7, .cls-8, .cls-9 {
							stroke: #1eef97;
							stroke-miterlimit: 10;
						}
						
						.cls-7 {
							fill: #1e7b68;
							stroke-width: 0.5px;
						}
						
						.cls-8 {
							fill: #1eb57f;
							stroke-width: 0.75px;
						}
						
						.cls-9 {
							fill: #1eef97;
						}
					`}
        </style>
      </defs>
      <path
        className="cls-1"
        d="M-101-255V766H899V-255ZM810,310H-10V-10H810Z"
      />
      <g>
        <text className="cls-2" transform="translate(280.23 197.16)">
          M
          <tspan className="cls-3" x="139.61" y="0">
            E
          </tspan>
          <tspan x="249.72" y="0">
            G
          </tspan>
          <tspan className="cls-4" x="365.05" y="0">
            A
          </tspan>
        </text>
        <g>
          <rect
            className="cls-5"
            x="113.57"
            y="71.33"
            width="144"
            height="144"
          />
          <rect
            className="cls-6"
            x="97.13"
            y="95.29"
            width="119.1"
            height="115.84"
            transform="translate(-6.71 299.26) rotate(-86.15)"
          />
          <rect
            className="cls-7"
            x="80.46"
            y="122.09"
            width="91.29"
            height="88.79"
            transform="translate(-59.89 261.49) rotate(-79.91)"
          />
          <rect
            className="cls-8"
            x="62.8"
            y="147.92"
            width="65.48"
            height="63.69"
            transform="translate(-106.72 202.81) rotate(-68.51)"
          />
          <rect
            className="cls-9"
            x="41.57"
            y="170.33"
            width="45"
            height="45"
            transform="translate(155.12 11.18) rotate(45)"
          />
        </g>
      </g>
    </svg>
  );
};

export default LogoFull;
