import { createApiRef } from '@backstage/core-plugin-api';
import { PaymentDetails, CreateProduct, Product, ProductStage } from '../types';
import { BaseProductMetaApiClient } from './baseApi';

export interface ProductMetaApi {
  getAllProducts: () => Promise<Array<Product>>;
  getProduct: (productName: string) => Promise<Product>;
  addProduct: (product: CreateProduct) => Promise<Product>;
  deleteProduct: (productName: string) => Promise<void>;
  updateProduct: (product: Product) => Promise<void>;
  addPayment: (productName: string, paymentDetails: PaymentDetails) => Promise<void>;
  deletePayment: (productName: string, paymentName: string) => Promise<void>;
  updatePayment: (productName: string, paymentDetails: PaymentDetails) => Promise<void>;
  setDefaultPayment: (productName: string, paymentName: string) => Promise<void>;
  updateStagePayment: (productName: string, stage: ProductStage) => Promise<void>;
  getCsvExport: () => Promise<void>;
}

export const productMetaApiRef = createApiRef<ProductMetaApi>({
  id: 'plugin.product-meta.service'
});

export class ProductMetaApiClient extends BaseProductMetaApiClient implements ProductMetaApi {
  async getAllProducts(): Promise<Array<Product>> {
    const response = await this.fetchWithAuth('/v1/products');
    return response.json();
  }

  async getProduct(productName: string): Promise<Product> {
    const response = await this.fetchWithAuth(`/v1/products/${productName}`);
    return response.json();
  }

  async addProduct(product: CreateProduct): Promise<Product> {
    const response = await this.fetchWithAuth(`/v1/products`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(product)
    });
    return response.json();
  }

  async updateProduct(product: Product): Promise<void> {
    await this.fetchWithAuth(`/v1/products/${product.identifier}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(product)
    });
  }

  async deleteProduct(productName: string): Promise<void> {
    await this.fetchWithAuth(`/v1/products/${productName}`, {
      method: 'DELETE'
    });
  }

  async addPayment(productName: string, paymentDetails: PaymentDetails) {
    const response = await this.fetchWithAuth(`/v1/products/${productName}/payments`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(paymentDetails)
    });
    return response.json();
  }

  async updatePayment(productName: string, paymentDetails: PaymentDetails) {
    const response = await this.fetchWithAuth(`/v1/products/${productName}/payments/${paymentDetails.name}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(paymentDetails)
    });
    return response.json();
  }

  async deletePayment(productName: string, paymentName: string) {
    await this.fetchWithAuth(`/v1/products/${productName}/payments/${paymentName}`, {
      method: 'DELETE'
    });
  }

  async setDefaultPayment(productName: string, paymentName: string) {
    const response = await this.fetchWithAuth(`/v1/products/${productName}/payments/${paymentName}/default`, {
      method: 'PATCH'
    });
    return response.json();
  }

  async updateStagePayment(productName: string, stage: ProductStage) {
    await this.fetchWithAuth(`/v1/products/${productName}/stages/${stage.name}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ paymentMethodId: stage.paymentMethodId })
    });
  }

  async getCsvExport() {
    const resp = await this.fetchWithAuth('/v1/products/export/csv', {
      method: 'GET'
    });

    const content = await resp.text();

    // Create blob link to download
    const url = window.URL.createObjectURL(new Blob([content]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `data.csv`);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode?.removeChild(link);
  }
}
